<!-- 消息通知 -->
<template>
  <div class="inform-container">

    <!-- 列表 -->
    <div class="inform-c" v-if="!addShow" :id="type == 5 ? 'boxwarn' : 'boxwarn'">
      <div class="" :class="type == 5 ? 'top-warn' : 'top-warn'">
        <div class="left">
          <div class="message-top">
            <span>消息通知</span>
          </div>
        </div>
        <div></div>
        <div class="top-centen">
          <div @click="() => $emit('handleClose')" class="closemessage cp">
            <img src="@/assets/img/i75.png" alt="" class="img">
          </div>
        </div>
      </div>
      <!-- 搜索 -->
      <div class="changeSearch">

        <div class="left">
          <el-select class="search-select" :class="type == 5 ? '' : ''" v-model="type" clearable placeholder="消息类型"
            :popper-append-to-body="true" @change="Change">
            <el-option class="option" :class="type == 5 ? 'list' : ''" v-for="item in typeList" :key="item.id"
              :label="item.dictionaryName" :value="item.id">
            </el-option>
          </el-select>
          <!-- 查询按钮 -->
          <el-button class="btn btn-search" icon="el-icon-search" size="small" @click="searchClick"></el-button>
        </div>
        <!-- <el-button size="small">小型按钮</el-button> -->
        <!-- <div @click="swicth" class="switchmessage ml8 cp" :class="{ swiach: isRead == 1 }">
          <span>已读</span>
          <span>({{ promptnumber.read }})</span>
        </div> -->
        <!-- <div @click="swicth" class="switchmessage ml8 cp" :class="{ swiach: isRead == 1 }">
          <span>已读</span> 

          <span>({{ promptnumber.read }})</span>
        </div>
        <div @click="isswicth" :class="{ swiach: isRead == 0 }" class="switchmessage ml4 cp">
          <span>未读</span>
          <span>({{ promptnumber.notRead }})</span>
        </div> -->
        <!-- <div class="switchmessage swiach ml184 cp" v-if="roleId == 1 || roleId == 7" @click="addInfo()">
          新建
        </div> -->
        <el-button class="btn btn-add switchmessage swiach  ml184 cp" icon="el-icon-plus" size="small"
          @click="add">新建</el-button>
      </div>
      <div :class="type == 5 ? 'gdtcentenwarn' : 'gdtcentenwarn'">
        <!-- tab页 -->
        <div class="title-line">
          <div class="title" :class="[isRead == 1 ? 'title-selected' : '']" @click="messageReadChange(1)">已读 ({{
            promptnumber.read }})</div>
          <div class="title" :class="[isRead == 0 ? 'title-selected' : '']" @click="messageReadChange(0)">未读 ({{
            promptnumber.notRead }})</div>
        </div>
        <!-- 消息体 -->
        <div class="message-centen" v-for="(item, index) in promptcenten" :key="index">

          <div class="middlecenten">
            <div class="title">{{ item.newsTitle }}</div>
            <div class="centent">
              <span>{{ item.releaseName }}</span><span class="ml15">{{ item.updatedTime }}</span>
            </div>
          </div>

          <div class="centenbreak">
            {{ newsContentFilter(item.newsContent) }}
          </div>
          <!-- <div class="statetype"> -->
          <!-- <div class="sorting">
              <div @click="openfile(item2.fileUrl)" v-for="item2 in item.mmcNewsFileList" :key="item2.id"
                v-show="item2.fileUrl" class="attachment mr30">
                <span class="iconfont icon-fujian mr5"></span>
                <span>{{ item2.fileName }}</span>
              </div>
            </div> -->
          <!-- <div @click="messageboxbtn(item)" v-show="isRead == 0" class="unread cp">
              <span class="iconfont icon-weidu mr5"></span><span class="mr5">未读</span>
            </div> -->
          <!-- <div v-show="isRead == 1" class="read cp">
              <span class="iconfont icon-duigouxiao mr5"></span><span class="mr5">已读</span>
            </div> -->
          <!-- </div> -->
          <!-- <div class="linechange"></div> -->
        </div>
      </div>
    </div>

    <!-- 新建 -->
    <div class="inform-c" v-else-if="addShow" :id="type == 5 ? 'boxwarn' : 'boxwarn'">
      <!-- <div class="yuandian"> </div> -->
      <div class="mb10 jubian" :class="type == 5 ? 'top-warn' : 'top-warn'">
        <div class="left">
          <div class="icon">
            <img src="@/assets/zong_lan_ye_img/frame/icon.png" alt="" />
          </div>
          <div class="message-top">
            <span>消息通知</span>
          </div>
        </div>
        <div></div>
        <div class="top-centen">
          <div @click="() => (addShow = false)" class="closemessage cp">
            返回
          </div>
        </div>
      </div>
      <div :class="type == 5 ? 'gdtcenten' : 'gdtcentenwarn'">
        <div class="message-centen">
          <el-form :model="ruleForm" ref="ruleForm" label-width="120px">
            <el-form-item label="消息标题:" prop="newsTitle">
              <el-input v-model="ruleForm.newsTitle" clearable placeholder="请输入消息标题"></el-input>
            </el-form-item>
            <el-form-item label="消息类型:" prop="newsType">
              <el-select v-model="ruleForm.newsType" clearable placeholder="请选择消息类型">
                <el-option class="option" v-for="item in typeList" :key="item.id" :label="item.dictionaryName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="消息内容:" prop="newsContent">
              <el-input v-model="ruleForm.newsContent" clearable type="textarea" placeholder="请输入消息内容"></el-input>
            </el-form-item>
            <el-form-item label="附件文件:" prop="mmcNewsFileList">
              <div class="files" v-for="(item, index) in options" :key="index">
                <el-input v-model="item.file"></el-input>
                <div class="text">
                  <el-upload v-if="options.length - 1 === index || options.length == 0" class="upload ml10" :action="url"
                    :multiple="false" :show-file-list="false" accept="*" :file-list="fileList" :on-change="onChange"
                    :on-remove="onRemove" :on-success="changeFile">
                    <span class="iconfont icon-fujian c08f"></span>
                  </el-upload>
                  <span class="iconfont icon-tianjia1 ml10 c08f"
                    v-if="options.length - 1 === index || options.length == 0" @click="add"></span>
                  <span class="iconfont icon-shanchu3 ml10 c08f" @click="deleteData(index)"></span>
                </div>
              </div>
            </el-form-item>
          </el-form>
          <div style="display: flex; justify-content: center" class="mt40">
            <div class="submit" @click="submitForm">提交</div>
          </div>
          <!-- <el-button class="submit" type="primary" @click="submitForm"
              >提交</el-button
            > -->
        </div>
      </div>
      <!-- <div class="message-bottom">
        <div><span class="iconfont icon-weidu"></span><span>未读</span></div>
      </div> -->
    </div>
  </div>
</template>
<script>
import API from "@/api";
export default {
  name: "Message",
  data () {
    return {
      cengji: null, //接收vuex传来的值,解决层级问题
      typeList: [],
      roleId: JSON.parse(localStorage.getItem("user_info")).data.roles[0]
        .roleId,
      ruleForm: {
        mmcNewsFileList: [
          {
            fileName: "",
            fileUrl: "",
            fileUrlName: "",
          },
        ],
      },
      url: "",
      type: null,
      promptnumber: {
        total: 0,
        read: 0,
        notRead: 0,
      },
      addShow: false,
      speed: 1,
      isRead: 1, // 0 未读， 1 已读
      promptcenten: null,
      newsId: null,
      timeMessagenumber: null,
      timeMessagecenten: null,
      fileList: [],
      options: [
        {
          file: null,
          fileName: null,
          fileUrl: null,
          fileUrlName: null,
        },
      ],
    };
  },
  mounted () {
    let baseUrl = process.env.VUE_APP_BASE_URL;
    this.url = baseUrl + "/upload/v2_uploads";
    // 消息类型
    this.getmessagetype();
    // 已读数量和未读数量
    this.messagenumber();
    // 消息内容
    this.messagecenten();

    // this.timeMessagenumber = setInterval(this.messagenumber, 2000);
    // this.timeMessagecenten = setInterval(this.messagecenten(this.type), 2000);
    //vuex层级问题(关键:层级问题)
    // this.cengji = this.$store.state.cengji.Zindex;
    // console.log(this.cengji, "查看层级问题");
  },
  methods: {
    addInfo () {
      this.addShow = true;
    },
    openfile (url) {
      window.open(url);
    },
    onRemove () {
      this.ruleForm.url = null;
    },
    onChange (file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; //这一步，是 展示最后一次选择文件
      }
    },
    async changeFile (response, file) {
      let fd = new FormData();
      fd.append("uploadFiles", file.raw);
      let res = await API.MAPMANAGE.fileUpload(fd);
      // if (res.status == 1) {
      this.options[this.options.length - 1].fileName = res[0].name;
      this.options[this.options.length - 1].file = res[0].name;
      this.options[this.options.length - 1].fileUrl = res[0].url;
      // } else {
      //   this.$message.error(res?.message);
      // }
    },
    // 增加
    add () {
      this.$message({
        type: 'warning',
        message: '暂未实现',
        duration: 1500
      });
      return;
      this.options.push({
        fileName: "",
        fileUrl: "",
        file: "",
      });
      this.ruleForm.mmcNewsFileList.push({
        fileName: "",
        fileUrl: "",
        fileUrlName: "",
      });
      // this.onchangeSelect();
    },
    // 删除
    deleteData (index) {
      if (this.options.length - 1 < 1) {
        this.$alert("至少要有一个附件");
        return;
      } else {
        this.options.splice(index, 1);
        this.ruleForm.mmcNewsFileList.splice(index, 1);
      }
    },
    Change () {
      // console.log(this.type, 'this.typethis.typethis.typethis.type');
      this.messagecenten(this.type);
    },
    submitForm () {
      if (this.options.length == 1 && this.options[0].file == null) {
        this.ruleForm.mmcNewsFileList = null;
      } else {
        this.options.forEach((val, index) => {
          if (val.file != null || val.file != "") {
            this.ruleForm.mmcNewsFileList[index].fileName = val.file;
            this.ruleForm.mmcNewsFileList[index].fileUrl = val.fileUrl;
            this.ruleForm.mmcNewsFileList[index].fileUrlName = val.fileName;
          }
        });
      }
      this.ruleForm.isRelease = 1;
      API.HOME.addnews(this.ruleForm).then((res) => {
        if (res == "创建成功") {
          this.$message.success(res);
          this.addShow = false;
          this.messagenumber();
        } else {
          this.$message.error(res);
        }
        this.ruleForm = {
          mmcNewsFileList: [
            {
              fileName: "",
              fileUrl: "",
              fileUrlName: "",
            },
          ],
        };
      });
    },
    async getmessagetype () {
      let res = await API.HOME.getmessagetype();
      this.typeList = res;
      // console.log(res, 'resresres');
      this.type = res[0].id;
    },
    async messageboxbtn (item) {
      if (item.id) {
        await API.HOME.readingmessage({ newsId: item.id });
      } else {
        await API.HOME.readingmessage({ waringId: item.waringId });
      }
      this.isswicth();
      this.messagenumber();
    },
    async messagenumber () {
      let res = await API.HOME.getmessagenumber();
      // console.log(res, '消息数量');
      this.promptnumber = res;
      this.$emit("numbeichange", this.promptnumber.notRead);
    },
    async messagecenten (type) {
      let res = null;
      if (type) {
        res = await API.HOME.getmessagecontent({
          isRead: this.isRead,
          newsId: this.newsId,
          type: type,
        });
      } else {
        res = await API.HOME.getmessagecontent({
          isRead: this.isRead,
          newsId: this.newsId,
        });
      }
      this.promptcenten = res;
      // console.log(res, 'resssssssssssssssssssssss');
    },
    async swicth () {
      this.isRead = 1;
      this.messagecenten(this.type);
    },
    async isswicth () {
      this.isRead = 0;
      this.messagecenten(this.type);
    },
    /**
    * description: 点击查询按钮
    * createtime:2023/09/21 19:31:09
    */
    searchClick () {
      this.messagecenten(this.type);
    },
    /**
    * description: 处理消息通知内容
    * createtime:2023/09/22 10:00:24
    */
    newsContentFilter (content) {
      let len = String(content).length;
      if (len > 65) {
        return String(content).substring(0, 65) + '...';
      }
      return String(content);
    },
    /**
    * description: 消息已读和未读切换
    * createtime:2023/09/22 10:20:01
    */
    messageReadChange (flag) {
      this.isRead = flag;
      this.messagecenten(this.type);
    }
  },
  beforeDestroy () {
    clearInterval(this.timeMessagecenten);
  },
  watch: {
    messagenum: {
      handler (val) {
        if (val.includes((num) => num == 5)) {
          this.messagenumber();
        }
      },
    },
  },
};
</script>
<style>
::v-deep .el-input__inner {
  background: #161d2d;
}
</style>
<style scoped lang="scss">
.z_index {
  z-index: 99 !important;
}

#box {
  width: 600px;
  height: 400px;
  background: rgba(47, 33, 33, 0.8);
  // border: 1px solid #979797;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  position: fixed;
  z-index: 10;
  top: 200px;
  // right: 600px;
  right: 50%;
  margin-right: -365px;

  .top-heafer {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    background: rgba(140, 56, 0, 0.2);
    box-shadow: inset 0 0 22px 0 rgba(255, 32, 0, 0.5);
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    padding: 0 0 0 24px;
    justify-content: space-between;

    .left {
      display: flex;
      justify-content: center;

      .message-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        // color: #00ffff;
        background-image: -webkit-linear-gradient(right,
            #e3aa77,
            #f5cda9,
            #f9ecd3,
            #fcdbb1,
            #edb07a);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: 0;
        font-weight: 700;
        margin-left: 4px;
      }
    }

    .top-centen {
      display: flex;
      align-items: center;
      margin: 0 0 0 35px;
      padding: 6px 0 0 0;
      height: 100%;

      .switchmessage {
        background: rgba(36, 0, 0, 0.4);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
          inset 0 0 15px 0 rgba(253, 77, 77, 0.7);
        border-radius: 6px;
        width: 120px;
        height: 27px;
        margin-top: -5px;
        line-height: 27px;
        text-align: center;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #ffa7a7;
        letter-spacing: 0;
        font-weight: 400;
      }

      .swiach {
        background: rgba(140, 0, 0, 0.7);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 20px 0 #fd4d4d;
        border-radius: 6px;
      }

      .closemessage {
        font-family: MicrosoftYaHei;
        font-size: 18px;
        color: #ed5944;
        letter-spacing: 0;
        font-weight: 400;
        margin: 0 35px 6px 0;
      }
    }
  }

  .gdtcenten {
    overflow-y: auto;
    width: 100%;
    height: 400px;

    .message-centen1 {
      width: 100%;
      // height: 400px;
      // padding: 20px 25px 30px 35px;
      border: 1px solid yellow;
      box-sizing: border-box;

      // margin-bottom: 55px;
      .middlecenten {
        display: flex;
        width: 100%;
        height: 100%;
        // padding: 30px 25px 30px 25px;
        justify-content: space-between;

        // box-sizing: border-box;
        .title {
          width: 103px;
          height: 28px;
          background: #ed5944;
          border: 1px solid #ed5944;
          text-align: center;
          line-height: 28px;
          font-family: MicrosoftYaHei-Bold;
          font-size: 14px;
          color: #ffffff;
          letter-spacing: 0;
          font-weight: 700;
        }

        .centent {
          font-family: MicrosoftYaHei-Bold;
          font-size: 16px;
          color: #ed5944;
          letter-spacing: 0;
          font-weight: 700;
        }
      }

      .centenbreak {
        background: url("~@/assets/images/warn/bg.png");
        width: 643px;
        height: 98px;
        text-align: left;
        // border: 1px solid #0083d3;
        padding: 10px 7px;
        box-sizing: border-box;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #f6d3ce;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 400;
        text-indent: 28px;
      }

      .statetype {
        display: flex;
        justify-content: space-between;

        .sorting {
          display: flex;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #00e0ff;
          letter-spacing: 0;
          font-weight: 400;
          cursor: pointer;
        }

        .unread {
          display: flex;
          justify-content: flex-end;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: rgba(237, 89, 68, 0.48);
          letter-spacing: 0;
          font-weight: 400;
        }

        .read {
          display: flex;
          justify-content: flex-end;
          // background-color: #0083d3 !important;
          color: #ed5944;
        }
      }

      .linechange {
        margin-top: 30px;
        height: 2px;
        width: 100%;
        background: linear-gradient(244deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(237, 89, 68, 1) 50%,
            rgba(255, 255, 255, 0) 100%) !important;
      }
    }
  }
}

#boxwarn {
  z-index: 10;
  box-sizing: border-box;
  padding: 10px 10px;
  padding: 0;

  .top-warn {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    background-color: #224D68;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;

    .left {
      display: flex;
      justify-content: center;

      .message-top {
        span {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }

    .top-centen {
      display: flex;
      align-items: center;
      margin: 0 0 0 35px;
      padding: 6px 0 0 0;

      .img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .gdtcentenwarn {
    overflow-y: auto;
    width: 100%;
    height: 335px;
    box-sizing: border-box;
    padding: 0 10px;

    .title-line {
      height: 40px;
      width: 100%;
      // background-color: #0083d3;
      display: flex;
      align-items: center;
      margin: 5px 0;

      .title {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        letter-spacing: 2px;
        cursor: pointer;
      }

      .title-selected {
        color: #00F5FF;
        border-bottom: 2px solid #00F5FF;
      }
    }

    .message-centen {
      width: 100%;
      box-sizing: border-box;
      height: 89px;
      background: #163B53;
      border-radius: 8px;
      margin-bottom: 10px;

      .el-form-item {
        // margin-bottom: 15px;
      }

      /deep/.el-select {
        .el-input__inner {
          border-radius: 6px;
        }

        .el-input .el-input__inner {
          height: 40px;
          border: 0;
        }
      }

      .middlecenten {
        display: flex;
        width: 100%;
        // height: 100%;
        justify-content: space-between;

        .title {
          text-align: center;
          color: #ffffff;
          letter-spacing: 1px;
          width: 73px;
          height: 29px;
          background: #0086DA;
          border-radius: 8px 0px 4px 0px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .centent {
          height: 29px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          padding: 0 10px;
        }
      }

      .centenbreak {
        height: calc(100% - 29px);
        width: 100%;
        text-align: left;
        box-sizing: border-box;
        letter-spacing: 0;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        box-sizing: border-box;
        padding: 5px 10px;
        line-height: 20px;
      }

      .statetype {
        display: flex;
        justify-content: space-between;

        .sorting {
          display: flex;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #00e0ff;
          letter-spacing: 0;
          font-weight: 400;
          cursor: pointer;
        }

        .unread {
          display: flex;
          justify-content: flex-end;
          color: rgb(44, 199, 220);
        }

        .read {
          display: flex;
          justify-content: flex-end;
          // background-color: #0083d3 !important;
          color: #0083d3;
        }
      }

      .linechange {
        margin-top: 30px;
        height: 2px;
        width: 100%;
        background: linear-gradient(244deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(59, 126, 194, 1) 50%,
            rgba(255, 255, 255, 0) 100%) !important;
      }
    }
  }
}

.changeSearch {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;

  .switchmessage {
    // width: 72px;
    // height: 32px;
    // background: #02173d;
    // box-shadow: inset 0px 0px 6px 2px rgba(38, 71, 238, 0.7);
    // border-radius: 2px;
    // border: 1px solid #315ec7;
    // line-height: 32px;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #d2d9ff;
    letter-spacing: 0;
    font-weight: 400;
  }

  .swiach {
    // background: rgba(0, 34, 140, 0.3) !important;
    background: url("~@/assets/zong_lan_ye_img/frame/messbg.png");
    background-size: 100%;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 10px 0 #00b6ff !important;
    // border-radius: 6px;
    // opacity: 1;
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 50%;

    .search-select {
      margin-top: -5px;
    }
  }

  .btn-search {
    width: 40px;
    height: 40px;
    background: #4E87AC;
    border-radius: 4px;
    color: #ffffff;
    border: 0;
    margin-left: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;
  }

  .btn-add {
    width: 85px;
    height: 40px;
    background: #4E87AC;
    border-radius: 4px;
    border: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;
  }
}

body #box ::-webkit-scrollbar {
  width: 7px;
  background-color: #333c57;
  height: 9px;
}

body #box ::-webkit-scrollbar-thumb {
  background: #ed5944 !important;
  border-radius: 8px;
}

.el-select {
  // width: 150px;
  margin-left: 0;
  width: 160px;
  height: 32px;
  background: #02173d;
  border-radius: 2px;
  // height: 27px;
}

/deep/.el-select-dropdown {
  top: 86px !important;
  left: 12px !important;
}

/deep/ .el-select .el-input__inner {
  // background: rgba(11, 23, 49, 0.8);
  background-color: #0A293A;
  // border: 1px solid #ED5944;
  // box-shadow: inset 0 0 3px 0 #00B6FF;
  width: 160px;
  height: 40px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  // color: #00b6ff;
  // color: #3B5461;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 400;

  // border: 1px solid #00b6ff;
  border: 1px solid #3B5461;
  // box-shadow: inset 0 0 3px 0 #00b6ff;
  box-shadow: inset 0 0 0 0 #ffffff;
  border-radius: 6px;

  &::-webkit-input-placeholder {
    // color: rgba(0, 182, 255, 0.7);
    color: #72797F;
  }
}

/deep/ .el-select .el-input__inner ::-webkit-input-placeholder {
  // color: rgba(0, 182, 255, 0.7);
  color: #fff;
}

/deep/.el-form {
  .el-form-item__content {
    width: 150px;

    .el-input,
    .el-select {
      width: 230px;
      margin-left: 0;

      .el-input__inner,
      .el-textarea__inner {
        width: 230px;
        background: rgba(11, 23, 49, 0.8);
        border: 1px solid rgba(14, 123, 117, 0.63);
      }
    }

    .el-textarea {
      width: 230px;
    }
  }
}

/deep/ .warnse .el-input__inner {
  background: rgba(39, 4, 3, 0.8);
  border: 1px solid #ed5944;
  // box-shadow: inset 0 0 3px 0 #00B6FF;
  width: 150px;
  height: 32px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #ed5944;
  letter-spacing: 0;
  font-weight: 400;
}

/deep/.warnse .el-input.is-focus .el-input__inner {
  border-color: #ed5944;
}

/deep/.el-select .el-input.is-focus .el-input__inner {
  // border-color: #ED5944;
}

/deep/.el-input__suffix {
  top: 3px !important;
  transition: rotate 0.3s;
}

/deep/.is-focus .el-input__suffix {
  top: -3px !important;
}

/deep/.is-focus {
  &:hover .el-input__suffix {
    top: 3px !important;

    .is-reverse {
      position: relative;
      top: -6px;
    }
  }
}

::v-deep .warnse .el-select-dropdown {
  .el-scrollbar {
    background: rgba(39, 4, 3, 0.8) !important;
    border: 1px solid #ed5944 !important;
    box-shadow: inset 0 0 3px 0 #00b6ff;
    border-radius: 0 0 6px 6px;

    .option {
      font-family: MicrosoftYaHei;
      font-size: 14px !important;
      color: #ed5944 !important;
      letter-spacing: 0;
      font-weight: 400;
    }

    .list.hover,
    .list:hover {
      background-image: linear-gradient(90deg,
          rgba(167, 101, 40, 0.7) 0%,
          rgba(155, 90, 30, 0.37) 51%,
          rgba(178, 119, 36, 0.37) 100%) !important;
      font-family: MicrosoftYaHei;
      font-size: 14px !important;
      color: #fdb04d !important;
    }
  }
}

::v-deep .el-select .el-select-dropdown {
  .el-scrollbar {
    // background: rgba(39,4,3,0.80) !important;
    // border: 1px solid #ED5944 !important;
    // box-shadow: inset 0 0 3px 0 #00B6FF;
    border-radius: 0 0 6px 6px;

    .option {
      font-family: MicrosoftYaHei;
      font-size: 14px !important;
      // color: #ED5944 !important;
      letter-spacing: 0;
      font-weight: 400;
    }

    .list.hover,
    .list:hover {
      background-image: linear-gradient(90deg,
          rgba(167, 101, 40, 0.7) 0%,
          rgba(155, 90, 30, 0.37) 51%,
          rgba(178, 119, 36, 0.37) 100%) !important;
      font-family: MicrosoftYaHei;
      font-size: 14px !important;
      // color: #FDB04D !important;
    }
  }
}

.el-popper .popper__arrow::after {
  background: rgba(39, 4, 3, 0.8) !important;
  border: 1px solid #ed5944 !important;
  color: #fff;
}

.files {
  display: flex;

  .el-input {
    .el-input__inner {
      width: 200px;
    }
  }

  .text {
    display: flex;
  }
}

.c08f {
  color: #08ffff;
}

.submit {
  width: 90px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: rgba(0, 34, 140, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 20px 0 #00b6ff;
  border-radius: 6px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #00ffff;
  letter-spacing: 0;
  font-weight: 400;
}

/deep/ .el-select .el-input .el-select__caret {
  color: rgba(0, 182, 255, 0.7);
}

.jubian {
  justify-content: space-between;
  padding: 0 35px 0 0;
}
</style>
  
<style lang="scss" scoped>
.inform-c {
  position: absolute !important;
  top: 8vh !important;
  right: .6vw !important;

  width: 520px !important;
  height: auto !important;
  background: #0A293A !important;
  // border-radius: 8px !important;
}
</style>